import { getIdb } from '@/idb';
import Settings from '@/models/Settings';
import { compare as jsonPatchCompare } from 'fast-json-patch';
import { DateTime } from 'luxon';
import { fetchWrap, idbResponse, isIdbResponse, offlineResponse } from '../_helpers';
import cache from './cache';

const idbStore = 'settings';

export default {
	async getSettings() {
		const idb = await getIdb();
		let response, data = null, timestamp;
		let useIdb = await cache.isCacheHit(idbStore);
		if (!useIdb) {
			try {
				timestamp = DateTime.now();
				response = await fetchWrap('/api/Settings');
				if (response.ok) { data = await response.json(); }
			} catch {
				useIdb = true;
				response = offlineResponse();
			}
		}
		if (useIdb && idb) {
			data = await idb.get(idbStore, 0);
			response = idbResponse(200);
		}
		if (response.ok) {
			if (idb && !isIdbResponse(response)) {
				await idb.put(idbStore, data, 0);
				await cache.setTimestamp(idbStore, timestamp);
			}
			return new Settings(data);
		} else {
			throw response;
		}
	},
	async getSettingsForPdf() {
		let response;
		try {
			response = await fetchWrap('/api/Settings/ForPdf');
		} catch {
			return null;
		}
		if (response.ok) {
			const data = await response.json();
			return new Settings(data);
		} else {
			return null;
		}
	},
	async getMyIp() {
		let response;
		try {
			response = await fetchWrap('/api/Settings/MyIp');
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return await response.text();
		} else {
			throw response;
		}
	},
	async getSettingsForUpdate() {
		let response;
		try {
			response = await fetchWrap('/api/Settings/ForEdit');
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			const data = await response.json();
			return new Settings(data);
		} else {
			throw response;
		}
	},
	/**
	 * Update settings
	 * @param {Settings} model settings to update.
	 * @returns (async) Returns the updated Settings if the request was successful, otherwise a Response.
	 */
	async update(model, oldModel) {
		const currentData = JSON.parse(JSON.stringify(oldModel));
		const newData = JSON.parse(JSON.stringify(model));
		const patch = jsonPatchCompare(currentData, newData);
		if (patch.length === 0) {
			return new Settings(newData);
		}
		return await this.patch(patch);
	},
	async patch(patch) {
		let response;
		try {
			response = await fetchWrap('/api/Settings', {
				method: 'PATCH',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(patch),
			});
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			await cache.clearTimestamp(idbStore);
			const data = await response.json();
			return new Settings(data);
		} else {
			return response;
		}
	}
};
