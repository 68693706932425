export default class WorkOrderPdfStyle {
	static standard = 0;
	static traditional = 1;

	static options = [
		{ id: this.standard, name: 'Standard (Default)' },
		{ id: this.traditional, name: 'Traditional' },
	];

	static display(value) {
		const item = this.options.find(x => x.id === value);
		return item ? item.name : '';
	}

	static isValid(value) {
		return value === this.standard || value === this.traditional;
	}
}
