export default class PaymentProcessor {
	static none = 0;
	static authorizeNet = 1;
	static heartland = 2;

	static options = [
		{ id: this.none, name: 'None' },
		{ id: this.authorizeNet, name: 'Authorize.Net' },
		{ id: this.heartland, name: 'Heartland Payment Systems' },
	];

	static display(value) {
		const item = this.options.find(x => x.id === value);
		return item ? item.name : '';
	}

	static isValid(value) {
		return value === this.none || value === this.authorizeNet || value === this.heartland;
	}
}
