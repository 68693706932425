import DurationUnit from './DurationUnit.js';
import { Duration as LuxonDuration } from 'luxon';

export default class Duration {
	constructor(dto) {
		this.amount = 0;
		this.unit = DurationUnit.day;

		if (typeof dto === 'object' && dto !== null) {
			if (typeof dto.amount === 'number') {
				this.amount = dto.amount;
			}
			if (typeof dto.unit === 'number' && DurationUnit.isValid(dto.unit)) {
				this.unit = dto.unit;
			}
		}
	}

	static get zero() {
		return new Duration();
	}

	toLocaleString() {
		return this.amount.toLocaleString() + ' ' + DurationUnit.display(this.unit, this.amount);
	}

	isValid(required) {
		return typeof this.amount === 'number' && (required ? this.amount >= 1 : this.amount >= 0) && DurationUnit.isValid(this.unit);
	}

	toLuxonDuration() {
		return LuxonDuration.fromObject({
			day: this.unit === DurationUnit.day ? this.amount : 0,
			week: this.unit === DurationUnit.week ? this.amount : 0,
			month: this.unit === DurationUnit.month ? this.amount : 0,
			year: this.unit === DurationUnit.year ? this.amount : 0,
		});
	}
}
