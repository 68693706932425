export default class DurationUnit {
	static day = 0;
	static week = 1;
	static month = 2;
	static year = 3;

	static options = [
		{ id: this.day, name: 'Day' },
		{ id: this.week, name: 'Week' },
		{ id: this.month, name: 'Month' },
		{ id: this.year, name: 'Year' },
	];

	static display(value, number) {
		const item = this.options.find(x => x.id === value);
		return item ? (item.name + (number === 1 ? '' : 's')) : '';
	}

	static isValid(value) {
		return value === this.day || value === this.week || value === this.month || value === this.year;
	}
}
